import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashLocal.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { LocalStore } from '../../utils/LocalStore/index';
import ReactLoading from 'react-loading';

export default class GcashLocal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            userId: "",
            rate: "",
            mmk: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",

            name: "",
            number: "",
            email: "",

            nameErr: "",
            numberErr: "",
            emailErr: "",
            onWaiting: false
        };
    }
    componentDidMount() {

        let idUser = new URLSearchParams(this.props.location.search).get("id")
        let chips = new URLSearchParams(this.props.location.search).get("chip")
        let mmk = new URLSearchParams(this.props.location.search).get("mmk")

        this.setState({
            isLoading: false,
            onWaiting: false,
            rate: mmk + " PHP = " + chips,
            userId: idUser,
            mmk: mmk,
            chips,
            name: LocalStore.getInstance().read('glc-input-name') || "",
            number: LocalStore.getInstance().read('glc-input-number') || "",
            email: LocalStore.getInstance().read('glc-input-email') || ""
        });

        // let data = {
        //     userId: idUser,
        //     amount: mmk,
        //     email: "techplay.studio@gmail.com",
        //     name: idUser,
        //     phoneNumber: idUser,
        //     source: "wepay"
        // }
        // PostApiUrl("https://gos.tongits.club/pay", data, (res) => {
        //     console.log(res)
        //     if (res.error !== 0) {
        //         toast.error(res.message);
        //         this.setState({ onWaiting: false });
        //     } else {
        //         // toast.success(res.message)    
        //         LocalStore.getInstance().save('glc-input-name', this.state.name);
        //         LocalStore.getInstance().save('glc-input-email', this.state.email);
        //         LocalStore.getInstance().save('glc-input-number', this.state.number);
        //         window.location.href = res.data;
        //     }
        // })
    }

    onNextClicked() {
        this.setState({ onWaiting: true });
        if (this.checkValidation()) {
            // {userId, amount, email, name, phoneNumber, source}
            let data = {
                userId: this.state.userId,
                amount: this.state.mmk,
                email: this.state.email,
                name: this.state.name,
                phoneNumber: this.state.number,
                source: "wepay"
            }
            PostApiUrl("https://gos.tongits.club/pay", data, (res) => {
                console.log(res, data)
                if (res.error !== 0) {
                    toast.error(res.message);
                    this.setState({ onWaiting: false });
                } else {
                    // toast.success(res.message)    
                    LocalStore.getInstance().save('glc-input-name', this.state.name);
                    LocalStore.getInstance().save('glc-input-email', this.state.email);
                    LocalStore.getInstance().save('glc-input-number', this.state.number);
                    window.location.href = res.data;
                }
            })
        } else {
            this.setState({ onWaiting: false });
        }
    }

    onTextChange(e) {
        console.log(e);
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    checkValidation() {
        let nameErr = "";
        let numberErr = "";
        let emailErr = "";
        var patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!patternEmail.test(this.state.email)) {
            emailErr = "Please enter valid email address."
        } else {
            emailErr = ""
        }

        var patternNumber = new RegExp(/^[0-9\b]+$/);
        if (!patternNumber.test(this.state.number) || this.state.number.length != 11) {
            numberErr = "Please enter valid phone number."
        } else {
            numberErr = ""
        }
        nameErr = this.state.name.length == 0 ? "Your name cant be empty." : ""

        this.setState({
            nameErr: nameErr,
            numberErr: numberErr,
            emailErr: emailErr,
        })

        return (nameErr.length == 0 && numberErr.length == 0 && emailErr.length == 0)
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash Local'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content glc">
                    <div className="container">
                        <p className="font-weight-bold text-center mb-3" style={{ fontSize: "17px" }}>Purchase <span className="text-danger">{this.state.rate}</span> chips <br></br> to user <span className="text-primary">{this.state.userId}</span>  </p>
                        <div className="main-contain">
                            <p className="font-weight-bold ml-2" style={{ fontSize: "16px" }}>Customer's Information</p>
                            <hr></hr>

                            <p className="mt-2">Customer's Name:</p>
                            <input type="text" value={this.state.name} className="glc-input-ref" id="name" placeholder="Enter your Name" aria-describedby="basic-addon1" onChange={(e) => this.onTextChange(e)}></input>
                            <p style={{ color: "#ff002f" }}>{this.state.nameErr}</p>

                            <p className="mt-2">Mobile Number:</p>
                            <input type="number" value={this.state.number} className="glc-input-ref" id="number" placeholder="Enter your Mobile Number here" aria-describedby="basic-addon1" onChange={(e) => this.onTextChange(e)}></input>
                            <p style={{ color: "#ff002f" }}>{this.state.numberErr}</p>

                            <p className="mt-2">Email:</p>
                            <input type="text" value={this.state.email} className="glc-input-ref" id="email" placeholder="Enter your Email here" aria-describedby="basic-addon1" onChange={(e) => this.onTextChange(e)}></input>
                            <p style={{ color: "#ff002f" }}>{this.state.emailErr}</p>

                            {this.state.onWaiting ?
                                <div className="two-button text-center mt-4 mb-4">
                                    <button disabled type="button" className="btn btn-primary ml-1 glc-btn-next" onClick={() => this.onNextClicked()} >
                                        NEXT
                                    </button>
                                    <div style={{ position: "absolute", top: "48%", left: "48%" }}>
                                        <ReactLoading type={'spinningBubbles'} color={'#ff8700'} height={'20px'} width={'20px'} />
                                    </div>
                                </div> :
                                <div className="two-button text-center mt-4 mb-4">
                                    <button type="button" className="btn btn-primary ml-1 glc-btn-next" onClick={() => this.onNextClicked()} >
                                        NEXT
                                    </button>
                                </div>
                            }

                        </div>
                    </div>

                </div>

            </MainLayout>
        );
    }
}