import { LocalStore } from "../LocalStore";
import Axios from "axios";

export function PostApiUrl(url, data, callback) {
    const method = "POST";
    const body = JSON.stringify(data);
    console.log(data);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Authorization': 'JWT ' + LocalStore.getInstance().read('loginSession')
    };
    fetch(url, {
        method, headers, body
    }).then((res) => res.json())
        .then((responseJson) => callback(responseJson))
        .catch((error) => {
            console.error('Error:', error);
            callback();
          });
}


export async function GetAxios(url) {
    try {
        return await Axios.get(url, {
            headers: {
                "Authorization": 'JWT ' + LocalStore.getInstance().read('loginSession')
            }
        });
    }
    catch (err) {
        if (err.status === 401) {
            window.location = '/#/login';
        }
        return ({ data: { data: err } });
    }

}

export function GetApiUrl(url, callback) {

    const method = "GET";
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    fetch(url, {
        method, headers
    }).then((res) => res.json())
        .then((responseJson) => callback(responseJson))
        .catch(console.error);
}