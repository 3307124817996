import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row } from 'antd';

export default class GcashToGcash extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            tid: "",
            token: "",
            accountList: [],
            source: "wepay"
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"

        // GetApiUrl("https://gcash.tongits.club/account", (res) => {
        //     console.log("https://gcash.tongits.club/account", res)
        //     let data = res.data;
        //     if (res.error === 0)
        //         this.setState({
        //             accountList: data
        //         })
        // })

        this.getAccount();

        this.setState({
            isLoading: true,
            rate: mmk + " PHP", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source
        });

        this.setTabIndex(0)
    }

    getAccount() {
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let data = {
            token: token,
        }

        // let url = "https://gcash.ph.notagame.club/account";
        let url = "https://gcash.tongits.club/account";
        PostApiUrl(url, data, (res) => {
            console.log("account: ", res, data)
            if (res.error === 0)
                this.setState({
                    accountList: res.data
                })
        })
    }

    onTextChange(e) {
        this.setState({
            tid: e.target.value
        })
    }

    onSubmit() {

        if (this.state.tid.length <= 0) {
            toast.error("Ref Number don't empty")
            return;
        }

        let data = {
            userId: this.state.userId,
            accountSent: "",
            accountReceive: this.state.acountReceipt,
            amount: this.state.mmk,
            transId: this.state.tid,
            source: this.state.source
        }

        // let url = "https://gcash.ph.notagame.club";
        let url = "https://gcash.tongits.club";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message)
            } else {
                // this.setTabIndex(2);
                toast.success(res.message)
                window.location = `/#/gtgscs?code=${this.state.token}&chip=${this.state.chips}`;
            }
        })
    }

    setTabIndex(index) {
        this.setState({ tabIndex: index })

        // const myElement = document.getElementById('tabList');
        // for (let i = 0; i < myElement.children.length; i++) {
        //     if (i < index)
        //         setTimeout(() => {
        //             let item = myElement.children[i];
        //             item.className += " item-success";
        //         }, 300);
        // }
    }

    onCopy(str) {
        // navigator.clipboard.writeText(str);

        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    onChangeAccountClick() {
        this.getAccount();
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">{this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Mangyaring magpadala lamang ng pera sa account na ipinapakita sa ibaba:</p>

                        <div className="ml-2 mr-2 mt-4 mb-4">
                            {this.state.accountList.map((acc, i) => {
                                return (
                                    <div className="gtg-bordered d-flex justify-content-between p-2" style={{ display: "flex", "justify-content": "center", "align-items": "center", backgroundColor: "#d7f1ff"}}>
                                        <div className="ml-2">
                                            <p className="text-left font-weight-bold">PHONE: {acc.phone}</p>
                                            <p className="text-left font-weight-bold">NAME: {acc.name}</p>
                                        </div>
                                        <div className="mr-2">
                                            <Button onClick={() => this.onCopy(acc.phone)} type="primary" style={{ backgroundColor: "#1c9dd4" }}>Copy</Button>
                                        </div>
                                    </div>)
                            })}
                        </div>

                        <div className="d-flex justify-content-between mb-1 mt-4" style={{ display: "flex", "justify-content": "center", "align-items": "center" }}>
                            <div className="ml-2 text-color-blue">
                                Kung hindi ka makalipat sa account sa itaas. Pakisubukan ang iba dito:
                            </div>
                            <div className="mr-2">
                                <button onClick={() => this.onChangeAccountClick()} type="button" className="btn btn-success btn-sm ml-2">Change account</button>
                            </div>
                        </div>

                        <div className="ml-2 mr-2 mt-4 mb-4">
                            <Row className="font-weight-bold gtg-bordered" style={{ fontSize: "14px", padding: "10px" }}>
                                <Col span={3}>
                                    <p className="text-left ">AMOUNT:</p>
                                </Col>
                                <Col span={17}>
                                    <p className="text-center" style={{ color: "#0040e3" }}>{this.state.rate}</p>
                                </Col>
                            </Row>
                            <Row className="font-weight-bold gtg-bordered" style={{ fontSize: "14px", padding: "10px" }}>
                                <Col span={3} style={{ fontSize: "14px", padding: "5px" }}>
                                    <p className="text-left ">MESSAGE:</p>
                                </Col>
                                <Col span={17} style={{ fontSize: "14px", padding: "5px" }}>
                                    <p className="text-center" style={{ color: "#0040e3" }}>{this.state.userId} ID</p>
                                </Col>
                                <Col span={4}>
                                    <Button onClick={() => this.onCopy(this.state.userId + ' ID')} type="primary" style={{ backgroundColor: "#1c9dd4" }} className="mr-2">Copy</Button>
                                </Col>
                            </Row>
                        </div>

                        <div className="text-center mt-3 mb-3">
                            <img className="img-fluid img-gif" style={{ width: "95%" }} src="images/gtg/guidegtg.png" alt="" />
                        </div>

                        <div style={{ fontSize: "16px", padding: "10px" }}>
                            <p className="font-weight-bold text-danger">Pay Attention!</p>

                            <p className="ml-2">👉  Hindi namin bubuksan ang Clip, ito ay mag eexpire pagkatapos ng 3 araw at maibabalik ito sa Gcash account ninyo.</p>
                            <p className="ml-2">👉  At dahil may limit ang Cash in ni Gcash, kapag ang aming account ay hindi na masendan, maaaring subukan ito sa iba.</p>

                            <p className="font-weight-bold text-danger mt-4 mb-2">Pagkatapos ng matagumpay na pagpapadala, ilagay ang iyong Ref.No sa kahon sa ibaba upang makatanggap ng chips:</p>

                            <input type="number" className="form-control input-ref" placeholder="Ilagay ang Ref. No" onChange={(e) => this.onTextChange(e)} value={this.state.tid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                            <div className="two-button text-center mt-4 mb-4">
                                <button type="button" className="btn btn-primary ml-1 btn-custom" onClick={() => this.onSubmit()} style={{ backgroundColor: "green" }}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}