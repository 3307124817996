/**
 */
import React, { Component } from 'react'
import DocumentTitle from "react-document-title"
import ProgressBar from '../Spinner/ProgressBar';
import Header from '../Header'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer'

const PERCENT_COMPLETE = 100;
class MainLayout extends Component {

    static defaultProps = {
        isLoadingPopup: false,
        isFocusSubmit: false
    }

    constructor(props) {
        super(props);
        this.state = {
            minified: false,
            percent: -1,
            autoIncrement: false,
            intervalTime: 200
        }
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showProgressBar !== this.props.showProgressBar) {
            // hiển thị progress bar
            if (this.props.showProgressBar === true) {
                this.startWithAutoIncrement();
            } else {
                // this.stopWithAutoIncrement();
                this.setPercent(PERCENT_COMPLETE);
            }
        }
    }

    minifyMenu = () => {
        this.setState({
            minified: !this.state.minified
        })
    };

    /**
     * hiển thị progressbar
     **/
    startWithAutoIncrement = () => {
        this.setState({
            percent: 0,
            autoIncrement: true,
            intervalTime: (Math.random() * 150)
        });
    };

    /**
     * Dừng progressbar
     **/
    stopWithAutoIncrement = () => {
        setTimeout(() => {
            this.setState({
                percent: -1,
                autoIncrement: false,
                intervalTime: 150
            });
        }, 500);
    };

    setPercent = (percent) => {
        this.setState({
            percent: percent
        }, () => {
            if (percent === PERCENT_COMPLETE) {
                this.stopWithAutoIncrement();
            }
        });
    };

    render() {
        const { title, className, isShowConfirm, onSubmitConfirm, onCancelConfirm, children, isShowRate, onCancelRate, onSubmitRate } = this.props;
        const { minified, percent, autoIncrement, intervalTime } = this.state;
        return (
            <DocumentTitle title={`${title ? title + ' | ' : ''} Mitdotpay`}>
                <div className={`wrapper position-re ${minified ? 'mnopen' : ''} ${isShowConfirm ? "wrappertrash" : ""} ${isShowRate ? "wrapperpoup" : ""}`}>
                    <div className="overlay zindex9999 dpl-none" onClick={this.minifyMenu} />
                    <Header {...this.props} minifyMenu={this.minifyMenu} />
                    <ProgressBar
                        percent={percent}
                        autoIncrement={autoIncrement}
                        intervalTime={intervalTime}
                        spinner={false}
                    />
                    <div className={`content pdt60 ${className} minheight100`}>
                        <div className="primary">
                            {children}
                        </div>
                        <Footer {...this.props}/>
                    </div>
                    <ToastContainer position="top-right"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover />
                </div>
            </DocumentTitle>
        )
    }
}

export default MainLayout;