import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row, Image } from 'antd';

import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "../../utils/AnimatedProgressProvider.js";
import { ArrowDownOutlined } from '@ant-design/icons';

export default class GtgInputNumber extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            accountList: [],
            source: "wepay",
            tid: "",
            tidSubmited: false,
            timer: 30,
            isFinished: false,
            tidSuccess: null
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"
        let nid = new URLSearchParams(this.props.location.search).get("nid") || ""
        let t = 30;

        this.setState({
            isLoading: true,
            rate: mmk + " PHP", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            nid,
            timer: t
        });

        let sefl = this;

        for (let i = 10; i <= t; i += 5) {
            if (!this.state.tidSuccess) {
                setTimeout(() => {
                    let data = {
                        token: token,
                        accountSent: nid
                    }
                    let url = "https://gcash.tongits.club/mobile-check";
                    PostApiUrl(url, data, (res) => {
                        if (!res || res.error != 0) {
                            if (i == t) {
                                sefl.setState({ isFinished: true });
                            }
                        } else {
                            if (res.data && res.data.transactionId) {
                                sefl.setState({ isFinished: true, tidSuccess: res.data.transactionId });
                            } else {
                                if (i == t) {
                                    sefl.setState({ isFinished: true });
                                }
                            }
                        }
                    })
                }, i * 1000);
            }
        }

        setTimeout(() => {
            let data = {
                token: token,
                accountSent: nid
            }
            let url = "https://gcash.tongits.club/mobile-check";
            PostApiUrl(url, data, (res) => {
                if (!res || res.error != 0) {
                    sefl.setState({ isFinished: true });
                } else {
                    if (res.data && res.data.transactionId) {
                        sefl.setState({ isFinished: true, tidSuccess: res.data.transactionId });
                    } else {
                        sefl.setState({ isFinished: true });
                    }
                }
            })
        }, t * 1000);
    }

    onTextChange(e) {
        this.setState({
            tid: e.target.value
        })
    }

    onSubmit() {
        if (this.state.tid.length <= 0) {
            toast.error("Ref.No can't empty")
            return;
        }

        let data = {
            userId: this.state.userId,
            accountSent: "",
            accountReceive: this.state.acountReceipt,
            amount: this.state.mmk,
            transId: this.state.tid,
            source: this.state.source
        }

        // let url = "https://gcash.ph.notagame.club";
        let url = "https://gcash.tongits.club";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message)
            } else {
                // this.setTabIndex(2);
                toast.success(res.message)
                this.setState({ tidSubmited: true });
            }
        })
        // this.setState({ tidSubmited: true });
    }

    backToEditNumber() {
        window.location = '/#/gtginputnum' + this.props.location.search.replaceAll(`&nid=${this.state.nid}`, '') + '&ne=inputnumber';
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">{this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Ang iyong Gcash number: {this.state.nid}</p>


                        <Row align='middle'>
                            {!this.state.isFinished &&
                                <Col align='middle' style={{ fontSize: "16px" }} span={24}>
                                    {/* <p className="text-center mb-2 text-danger font-weight-bold" style={{ fontSize: "26px" }}>{this.state.nid}</p> */}
                                    <p className="text-center mb-2 text-primary font-weight-bold" style={{ fontSize: "14px" }}>Ang iyong transaksyon ay pinoproseso...</p>
                                </Col>
                            }

                            {!this.state.isFinished &&
                                <Col style={{ width: "250px", marginBottom: "20px" }} align='middle' span={10} offset={7}>
                                    <AnimatedProgressProvider
                                        valueStart={0}
                                        valueEnd={100}
                                        duration={this.state.timer}
                                        easingFunction={easeQuadInOut}
                                    >
                                        {value => {
                                            const roundedValue = Math.round(value);
                                            return (
                                                <CircularProgressbar
                                                    value={value}
                                                    text={`${roundedValue}%`}
                                                    /* This is important to include, because if you're fully managing the
                                              animation yourself, you'll want to disable the CSS animation. */
                                                    styles={buildStyles({
                                                        pathTransition: "none",
                                                        textColor: "red",
                                                        pathColor: "turquoise",
                                                        trailColor: this.state.nextpage == 'result' ? 'green' : "gold",
                                                    })}
                                                />
                                            );
                                        }}
                                    </AnimatedProgressProvider>

                                </Col>
                            }


                            {this.state.isFinished && this.state.tidSuccess &&
                                <Col align='middle' span={24}>
                                    <p style={{ color: 'green', fontWeight: 'bold', fontSize: '16px' }}>MATAGUMPAY</p>
                                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Ref.No {this.state.tidSuccess}</p>
                                    <p style={{ fontSize: '14px' }}>Bumalik sa laro at tignan!</p>
                                </Col>
                            }
                            {this.state.isFinished && !this.state.tidSuccess &&
                                <Col align='middle' span={24}>
                                    <p style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>HINDI MATAGUMPAY</p>
                                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Pakibigay ang iyong Ref. No</p>

                                    <p style={{ color: 'red', fontWeight: 'bold', fontSize: '50px' }}><i class="fa fa-arrow-down"></i></p>
                                </Col>
                            }

                        </Row>
                        {this.state.tidSubmited &&
                            <Row style={{ fontSize: "16px", padding: "10px", backgroundColor: '#e6e6e6', marginBottom: '10px' }} align='middle'>
                                <Col span={24} style={{ textAlign: 'center', fontWeight: 'bold', padding: '5px' }}>Ang iyong Ref. No ay <span style={{ fontSize: '18px' }}>{this.state.tid}</span> </Col>
                                <br></br>
                                <Col span={24} style={{ textAlign: 'center', padding: '5px' }}>Ang chips ay darating sa loob nang 30 minuto.</Col>
                                <Col span={24} style={{ textAlign: 'center', padding: '5px' }}>Bumalik sa laro at tignan!</Col>
                                <Col span={24} style={{ textAlign: 'center', padding: '5px', color: 'red', fontSize: '13px' }}>PAALALA: Kung ang iyong Ref. No ay tama pero hindi dumating ang iyong chips ang 30 minuto, makipag ugnayan sa aming admin gamit ang Messenger (Facebook)</Col>
                            </Row>
                        }
                        {!this.state.tidSubmited && !this.state.tidSuccess &&
                            <Row style={{ fontSize: "16px", padding: "10px", backgroundColor: '#e6e6e6', marginBottom: '10px' }} align='middle'>
                                <Col span={24} style={{ textAlign: 'center', fontWeight: 'bold', padding: '5px', color: 'red' }}>Paalala</Col>
                                <Col span={24} style={{ textAlign: 'center', fontWeight: 'bold', padding: '5px' }}>Ilagay ang iyong Ref. No kung HINDI MATAGUMPAY</Col>
                                <input type="number" pattern="\d*" className="form-control input-ref" placeholder="I-tap at ilalagay ang Ref. no dito" onChange={(e) => this.onTextChange(e)} value={this.state.tid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                                <Col span={24} className="mt-2" align='middle'>
                                    <button type="button" className="btn btn-primary ml-1 font-weight-bold" onClick={() => this.onSubmit()} style={{ backgroundColor: "#0eaf01", fontSize: '16px' }}>IPADALA</button>
                                </Col>
                            </Row>
                        }

                        {/* <Row align='middle'>
                            <p style={{ textAlign: 'center', fontWeight: 'bold', padding: '5px' }}>Makukuha nyo ang inyong chips pagkalipas ng 30 minuto pagkatapos nyo mailagay ang Ref. No.</p>
                        </Row>

                        <Row align="left">
                            <h7 style={{ textAlign: 'left', padding: '5px' }}>* Kung pagkalipas ng 30 minuto at hindi parin nakukuha ang chips. Maaaring makipag ugnayan sa admin gamit ang messenger sa ibaba.</h7>
                            <br></br>
                            <br></br>
                        </Row> */}

                        {(!this.state.tidSubmited && !this.state.tidSuccess) &&
                            <Row align='middle' style={{ margin: '5px' }}>
                                <Col span={24} align='middle'>
                                    <Image src='images/gtg/p2.png' maxWidth="98%" />
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            </MainLayout>
        );
    }
}