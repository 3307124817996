import React from "react";
import { HashRouter as Router, Route, Link, Redirect } from "react-router-dom";
import GcashToBank from './containers/GcashToBank';
// import WingToWing from './containers/WingToWing';
import WingToWing from './containers/WingToWing/wtw';
import WingSuccess from './containers/WingToWing/success';
import GcashLocal from './containers/GcashLocal';
import GcashToGcash from './containers/GcashToGcash';
import GtG1 from './containers/GcashToGcash/gtg1';
import MTM from './containers/MayaToMaya/mtm';
import MtmSuccess from './containers/MayaToMaya/mtmSuccess';
import GtGLoading from './containers/GcashToGcash/gtgloading';
import GtgInputNumber from './containers/GcashToGcash/gtgInputNumber';
import GcashSuccess from './containers/GcashToGcash/success';
import GtgSuccess from './containers/GcashToGcash/gtgSuccess';
import GtgInputTid from './containers/GcashToGcash/gtgInputTid';
import DragonPay from './containers/DragonPay';
import WaveToWave from './containers/WaveToWave';
import WingInfo from './containers/WingToWing/wingToWingInfo';

function Routes() {
    return (
        <Router>
            <div className={"nota"}>
                <Route path="/gtb" component={GcashToBank} />
                <Route path="/gtgscs" component={GcashSuccess} />
                <Route path="/wtw" component={WingToWing} />
                <Route path="/wtwscs" component={WingSuccess} />
                <Route path="/glc" component={GcashLocal} />
                <Route path="/gtg" component={GtG1} />
                
                <Route path="/gtgload" component={GtGLoading} />
                <Route path="/gtginputnum" component={GtgInputNumber} />
                <Route path="/gtgsuccess" component={GtgSuccess} />
                <Route path="/gtginputtid" component={GtgInputTid} />
                <Route path="/mtm" component={MTM} />
                <Route path="/mtmscs" component={MtmSuccess} />
                <Route path="/dp" component={DragonPay} />
                <Route path="/wave" component={WaveToWave} />
                <Route path="/wtwaddinfo" component={WingInfo} />
            </div>
        </Router>
    );
}

export default Routes;
