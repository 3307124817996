import React, { Component } from 'react'

import './header.css';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        if (this.props.color === "gcash")
            return (
                <header className="header-page mb-2 pb-1" style={{ backgroundColor: "#0071ba" }}>
                    <div className="container">
                        <img className="img-fluid" src="images/logo.png" alt="" />
                    </div>
                </header >
            );

        else if (this.props.color === "dragon") {
            return (
                <header className="header-page mb-2 pb-4" style={{ backgroundColor: "#c3121a" }}>
                    <div className="container">
                        {/* <img className="img-fluid" src="images/logo.png" alt="" /> */}
                    </div>
                </header >
            );
        } else if (this.props.color === "wave") {
            return (
                <header className="header-page mb-2 pb-4 d-none" style={{ backgroundColor: "#c3121a" }}>
                    <div className="container">
                        {/* <img className="img-fluid" src="images/logo.png" alt="" /> */}
                    </div>
                </header >
            );
        } else if (this.props.color === "winginfo") {
            return (
                <header className="header-page pb-2" style={{ backgroundColor: "#a9cb37" }}>
                    <div className="container">
                        <img className="img-fluid" src="images/logow.png" alt="" />
                    </div>
                </header >
            );
        } else if (this.props.color === "maya") {
            return (
                <header className="header-page mb-2 pb-1" style={{ backgroundColor: "#000000" }}>
                    <div className="container">
                        <img className="img-fluid" src="images/logom.png" alt="" />
                    </div>
                </header >
            );
        }
        else {
            return (
                <header className="header-page mb-2 pb-1" style={{ backgroundColor: "#A9CB37" }}>
                    <div className="container">
                    <img className="img-fluid" src="images/logow.png" alt="" />
                    </div>
                </header >
            );
        }
    }
}

export default Header;