import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row, Image } from 'antd';
import { LocalStore } from '../../utils/LocalStore/index';

export default class GcashToGcash extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            accountList: [],
            source: "wepay",
            phoneRecive: {},
            savenumber: [],
            nid: '',
            hasNext: false
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"

        let sNumber = LocalStore.getInstance().read('gtg-save-number') || '';
        (sNumber.length > 0) ? sNumber = sNumber.split(',') : sNumber = [];

        this.getAccount();

        this.setState({
            isLoading: true,
            rate: mmk + ".00", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            savenumber: sNumber
        });

        this.setTabIndex(0)
    }

    getAccount() {
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let data = {
            token: token,
        }

        // let url = "https://gcash.ph.notagame.club/account";
        let url = "https://gcash.tongits.club/account";
        PostApiUrl(url, data, (res) => {
            console.log("account: ", res, data)
            if (res.error === 0 && res.data && res.data.length > 0)
                this.setState({
                    accountList: res.data,
                    phoneRecive: res.data[0]
                })
        })
    }

    onTextChange(e) {
        this.setState({
            nid: e.target.value
        })
    }

    setTabIndex(index) {
        this.setState({ tabIndex: index })
    }

    onCopy(str) {
        // navigator.clipboard.writeText(str);

        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    onChangeAccountClick() {
        this.getAccount();
    }

    showInputNumber() {
        window.location = '/#/gtgload' + this.props.location.search + '&ne=inputnumber';
    }

    onShowGcash() {
        if (this.state.nid.length == 0) {
            toast.error("Pakilagay ang iyong Gcash number");
            return;
        }
        if (this.state.nid.length != 11 || !this.state.nid.startsWith('09')) {
            toast.error("Gcash number includes only 11 numbers starting with 09");
            return;
        }

        let data = {
            token: this.state.token,
            accountSent: this.state.nid,
            source: this.state.source
        }

        // let url = "https://gcash.ph.notagame.club";
        let url = "https://gcash.tongits.club/mobile";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (!res || res.error !== 0) {
                toast.error('error')
            } else {
                // this.setTabIndex(2);
                toast.success('Magpatuloy')
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.nid) && sN.length < 2) {
                    sN.push(this.state.nid);
                } else if (!sN.includes(this.state.nid)) {
                    sN.push(this.state.nid);
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('gtg-save-number', sN.toString());

                this.setState({hasNext: true});
            }
        })
    }

    onShowCheckPage() {
        window.location = '/#/gtgscs';
    }

    showInputTid() {
        if (this.state.nid.length == 0) {
            toast.error("Pakilagay ang iyong Gcash number");
            return;
        }
        if (this.state.nid.length != 11 || !this.state.nid.startsWith('09')) {
            toast.error("Gcash number includes only 11 numbers starting with 09");
            return;
        }

        let data = {
            token: this.state.token,
            accountSent: this.state.nid,
            source: this.state.source
        }

        // let url = "https://gcash.ph.notagame.club";
        let url = "https://gcash.tongits.club/mobile";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (!res || res.error !== 0) {
                toast.error('error')
            } else {
                // this.setTabIndex(2);
                toast.success(res.message)
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.nid) && sN.length < 2) {
                    sN.push(this.state.nid);
                } else if (!sN.includes(this.state.nid)) {
                    sN.push(this.state.nid);
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('gtg-save-number', sN.toString());

                window.location = '/#/gtginputtid' + this.props.location.search + '&nid=' + this.state.nid;
            }
        })
    }

    setGcashNumber(s) {
        this.setState({ nid: s });
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        {!this.state.hasNext &&
                            <>
                                <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">₱ {this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                                {/* <p className="text-center mb-2" style={{ fontSize: "16px" }}>Mangyaring magpadala lamang ng pera sa account na ipinapakita sa ibaba:</p> */}
                                <p style={{ color: 'red', fontWeight: 'bold' }}>HAKBANG 1: </p>
                                <div style={{ fontSize: "14px", padding: "10px", border: '2px solid #1890ff', padding: '5px' }}>
                                    <p style={{ fontWeight: 'bold' }}>Ang iyong Gcash number:</p>
                                    <input type="number" pattern="\d*" className="form-control input-ref" placeholder="Ilagay ang iyong Gcash number dito" onChange={(e) => this.onTextChange(e)} value={this.state.nid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                                    {this.state.savenumber.length > 0 &&
                                        <Row align="middle" className="">
                                            <Col span={8} align="left">
                                                <p>Suhestiyon:</p>
                                            </Col>
                                            {this.state.savenumber.map((s) =>
                                                <Col xs={8} xl={8} align="left">
                                                    <Button style={{ width: 'auto', height: '30px', backgroundColor: '#c5dbff', marginTop: '10px', borderRadius: '6px' }} className="p-1" onClick={() => this.setGcashNumber(s)}>
                                                        {s}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    }
                                </div>
                                <Row align='middle'>
                                    <Col span={24} align="middle">
                                    <Button size='large' style={{ borderRadius: "5px", color: '#fff', backgroundColor: "#0daf01", fontSize: "14px", fontWeight: "bold", padding: '10px 20px 30px 20px' }} onClick={() => this.onShowGcash()}>Next</Button>
                                </Col>
                            </Row>

                            </>
                        }

                    {this.state.hasNext &&
                        <><p style={{ color: 'red', fontWeight: 'bold', marginTop: '20px' }}>HAKBANG 2: </p>
                            <Row style={{ border: '2px solid #1890ff', padding: '5px' }} align="middle">
                                <Col span={24} >
                                    <p style={{ fontWeight: 'bold' }}>Ipadala ang pera sa account na nasa ibaba:</p>
                                    <p className="text-danger">Magpadala lang sa Gcash account na lalabas sa screen ngayon. Pagpapadala sa ibang Gcash account ay maaaring mawala ang iyong pera at hindi mo matanggap ang iyong chips.</p>
                                </Col>

                                <Col span={16} offset={4} style={{ border: '1px solid black', marginTop: '5px' }}>
                                    <Row style={{ width: '100%', height: 'auto' }}>
                                        <Col span={24} align='middle' style={{ backgroundColor: "#0106ff", color: 'white', fontWeight: 'bold', padding: '6px' }}>
                                            Express Send
                                        </Col>
                                        <Col span={24} align='middle'>
                                            <Row align='middle' style={{}}>
                                                <Col span={24} align='left'>
                                                    <div style={{ padding: '1px 10px' }}>
                                                        Send to
                                                    </div>
                                                </Col>
                                                <Col span={16} align='left' style={{ padding: '0 10px' }}>
                                                    <div style={{ border: '1px solid black', padding: '4px', color: '#0106ff', fontWeight: 'bold' }}>
                                                        {this.state.phoneRecive.phone}
                                                    </div>
                                                </Col>

                                                <Col span={8} align='middle'>
                                                    <div>
                                                        <Button type="primary" onClick={() => this.onCopy(this.state.phoneRecive.phone)} >Copy</Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row align='middle' style={{}}>
                                                <Col span={24} align='left'>
                                                    <div style={{ padding: '1px 10px' }}>
                                                        Amount
                                                    </div>
                                                </Col>
                                                <Col span={16} align='left' style={{ padding: '0 10px', marginBottom: '10px' }}>
                                                    <div style={{ border: '1px solid black', padding: '4px', color: '#0106ff', fontWeight: 'bold' }}>
                                                        ₱ {this.state.rate}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col align='middle' span={24} style={{ fontSize: '12px' }}>
                                    <Row className="mt-2" align="middle">
                                        <Col span={18} className="" align="middle" style={{ padding: '10px, 5px' }}>
                                            Kung hindi makapagpadala nang pera sa Gcash number na ito, subukan ang ibang Gcash number dito:
                                        </Col>
                                        <Col span={6} align="middle">
                                            <button onClick={() => this.onChangeAccountClick()} style={{ fontSize: '12px', fontWeight: 'bold', padding: '2px' }} type="button" className="btn btn-warning">Palitan ang Gcash</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row align="middle" style={{ marginTop: "10px", marginBottom: "" }}>
                                <Col span={24} align='middle'>
                                    <Button size='large' style={{ borderRadius: "5px", color: '#fff', backgroundColor: "#0daf01", fontSize: "14px", fontWeight: "bold", padding: '10px 20px 30px 20px' }} onClick={() => this.onShowCheckPage()}>Magpatuloy pagkatapos maipadala ang pera</Button>
                                </Col>
                            </Row>

                            <div style={{ fontSize: "16px", padding: "10px" }}>
                                <p className="font-weight-bold text-danger">Pay Attention!</p>

                                <p className="ml-2">👉  Hindi namin bubuksan ang Clip, ito ay mag eexpire pagkatapos ng 3 araw at maibabalik ito sa Gcash account ninyo.</p>
                                <p className="ml-2">👉  At dahil may limit ang Cash in ni Gcash, kapag ang aming account ay hindi na masendan, maaaring subukan ito sa iba.</p>
                            </div></>
                    }
                </div>

            </div>
            </MainLayout >
        );
    }
}