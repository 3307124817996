
import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export default class GcashSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            pageId: "104055704861817",
            appId: "272959961281350",
            chips: "5,000",
        };
    }

    componentDidMount() {
        let pageId = new URLSearchParams(this.props.location.search).get("pd") || '104055704861817';
        this.setState({
            pageId: pageId
        });
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Wing to Wing'} color="wtw">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <div className="wrapper-tab3 text-center">
                            <br></br><br></br><br></br><br></br><br></br><br></br>
                            <h3 className="text-primary font-weight-bold">អបអរសារទរ!</h3>
                            <p className="font-weight-bold">អ្នកបានផ្ញើរប្រាក់ជោគជ័យ.</p>
                            <p className="font-weight-bold">កាក់នឹងដាក់ចូលទៅក្នុងគណនីយរបស់អ្នកក្នុងពេល <span className="text-primary">5</span> នាទី.</p>
                            <br></br><br></br>
                            <p className="text-danger">ចំណាំ៖ អ្នកនឹងមិនទទួលបានប្រូម៉ូសិន 100% ទេប្រសិនបើគណនី WING របស់អ្នកធ្លាប់បានទិញកាក់ក្នុងហ្គេមពីមុនរួចហើយ។</p>
                            <br></br><br></br>
                            <p>ប្រសិនបើអ្នកមិនទាន់ទទួលបានកាក់ឬមានបញ្ហាផ្សេងៗ។ សូមរងចាំហើយទាក់ទងទៅកាន់ admin Facebook ដើម្បីដោះស្រាយ។ សូមអរគុណ</p>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

