import React, { Component, useState } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './wingToWing.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Form, Input, Button, Select, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import { PostApiUrl } from '../../utils/fetchApi'
import moment from 'moment'


const { Option } = Select;
const d = moment().format('YYYY-MM-DD')
const d1 = moment().subtract(1, 'd').format('YYYY-MM-DD')
const d2 = moment().subtract(2, 'd').format('YYYY-MM-DD')
console.log(d, d1, d2);
var listHour = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
var listMin = [];
for (let i = 0; i < 60; i++) {
    let x = ''
    if (i < 10)
        x = '0' + i
    else x = i + ''
    listMin.push(x);
}
var hour = '00';
var min = '00';
var sec = '00';

export default class WingToWing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mmk: "",
            wingIdNumber: "1234567890",
            acountReceipt: "chungtoilachiensi",
            mailSuport: "nota.shan777@gmail.com",
            chips: "5,000",
            pageId: "104055704861817",
            appId: "272959961281350",
            timeString: "",
            dayCurrentPick: "",
            timeCurrentPick: "",
            day: d,
            day1: d1,
            day2: d2,
            amPm: null,
            isEnableBtnSubmit: true
        };
    }

    componentDidMount() {

        let idUser = new URLSearchParams(this.props.location.search).get("id")
        let chips = new URLSearchParams(this.props.location.search).get("chip")
        let mmk = new URLSearchParams(this.props.location.search).get("amount")

        this.setState({
            isLoading: true,
            rate: mmk + " USD = " + chips,
            userId: idUser,
            mmk: "$" + mmk,
            chips
        });
    }

    onFinish = (values) => {
        console.log('Success:', values);
        let token = new URLSearchParams(this.props.location.search).get("code") || 0;
        let accountSent = new URLSearchParams(this.props.location.search).get("accountSent") || 0;
        let tid = new URLSearchParams(this.props.location.search).get("tid") || 0;

        // let {token, accountSent, accountReceive, amount, transId, source, currency, time} = req.body;

        let data = {
            token: token,
            accountSent: accountSent,
            accountReceive: values.wingAccount,
            amount: values.amount,
            transId: tid,
            source: "web",
            currency: values.currency,
            time: this.state.timeString
        }

        let url = "https://wing.win777.casino/pay/extend";
        PostApiUrl(url, data, (res) => {
            console.log("account: ", res, data)
            if (res && res.error == 0) {
                toast.success(res.message);
                this.setState({ isEnableBtnSubmit: false });
            } else {
                toast.error(res.message);
            }
        })
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    onChangeDateTime = (value, timeString) => {
        // console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', timeString);
        this.setState({ timeString: timeString });
    }

    onChangeDate = (value) => {
        this.state.dayCurrentPick = value
        this.setState({ timeString: this.formatTime(hour, min, sec) });
    }

    onChangeTimeHour = (value, timeString) => {
        hour = value
        if (hour && hour == '00') {
            this.setState({ amPm: 'AM' })
        } else if (hour && hour == '12') {
            this.setState({ amPm: 'PM' })
        }
        this.setState({ timeString: this.formatTime(hour, min, sec, this.state.amPm) })
    }

    onChangeTimeMin = (value, timeString) => {
        min = value
        this.setState({ timeString: this.formatTime(hour, min, sec, this.state.amPm) })
    }

    onChangeTimeSec = (value, timeString) => {
        sec = value
        this.setState({ timeString: this.formatTime(hour, min, sec, this.state.amPm) })
    }

    onChangeAMPM = (value) => {
        if (hour && hour == '00') {
            value = 'AM';
            this.setState({ amPm: 'AM' })
        } else if (hour && hour == '12') {
            value = 'PM';
            this.setState({ amPm: 'PM' })
        } else {
            this.setState({ amPm: value })
        }
        this.setState({ timeString: this.formatTime(hour, min, sec, value) })
    }

    formatTime(hh, mm, ss, T = 'AM') {
        if (T == 'AM')
            return `${this.state.dayCurrentPick} ${hh}:${mm}:${ss}`
        else {
            let H = parseInt(hh) + 12
            return `${this.state.dayCurrentPick} ${H}:${mm}:${ss}`
        }
    }

    onOkDateTime(value) {
        console.log('onOk: ', value);
    }

    // disabledDate = (current) => {
    //     const start = moment();
    //     return current < start.subtract(1, 'd') || current > moment().add(1, 'd');
    // }

    render() {

        return (
            <MainLayout {...this.props} title={'Wing To Wing'} color="winginfo">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content wtwi" style={{ minHeight: '100vh', color: 'white' }}>
                    <div className="container">
                        <p className="font-weight-bold text-center mb-2" style={{ fontSize: "16px" }}>Purchase <span style={{ color: 'yellow' }}>{this.state.rate}</span> chips to user <span className="text-primary">{this.state.userId}</span>  </p>
                        <p className=" text-center mb-2"> ចំពោះ TID 16 ខ្ទង់ សូមមេត្តាផ្តល់ព័ត៌មានបន្ថែមដូចខាងក្រោ </p>
                        <br />
                        <br />
                        <Row>
                            <Col span={14}>
                                <Form
                                    name="basic"
                                    layout="vertical"
                                    labelCol={{
                                        span: 24,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="លេខគណនីយអ្នកទទួល Wing:"
                                        name="wingAccount"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'សូមបញ្ចូលព័ត៌មាននេះ!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Row>
                                        <Col span="14">
                                            <Form.Item label="ចំនួនទឹកប្រាក់:"
                                                name="amount"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមបញ្ចូលព័ត៌មាននេះ!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span="9" offset="1">
                                            <Form.Item label="រូបិយប័ណ្ណ:"
                                                name="currency"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមបញ្ចូលព័ត៌មាននេះ!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 24,
                                                }}
                                            >
                                                <Select >
                                                    <Option value="USD">USD</Option>
                                                    <Option value="KHR">KHR</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={8}>
                                            <Form.Item label="កាលបរិឆ្ឆេទ:"
                                                name="selectdate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមជ្រើសព័ត៌មានឱ្យបានគ្រប់គ្រាន់!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}>
                                                {/* <DatePicker disabledDate={this.disabledDate} showTime onChange={this.onChangeDateTime} onOk={this.onOkDateTime} /> */}
                                                <Select onChange={this.onChangeDate}>
                                                    <Option value={this.state.day}>{this.state.day}</Option>
                                                    <Option value={this.state.day1}>{this.state.day1}</Option>
                                                    <Option value={this.state.day2}>{this.state.day2}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span="4">
                                            <Form.Item label="ម៉ោង:"
                                                name="hour"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមជ្រើសព័ត៌មានឱ្យបានគ្រប់គ្រាន់!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}>
                                                <Select onChange={this.onChangeTimeHour}>
                                                    {listHour.map(item => <Option key={item} value={item}>{item}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="នាទី។:"
                                                name="min"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមជ្រើសព័ត៌មានឱ្យបានគ្រប់គ្រាន់!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}>
                                                <Select onChange={this.onChangeTimeMin}>
                                                    {listMin.map(item => <Option key={item} value={item}>{item}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="វិនាទី:"
                                                name="sec"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមជ្រើសព័ត៌មានឱ្យបានគ្រប់គ្រាន់!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}>
                                                <Select onChange={this.onChangeTimeSec}>
                                                    {listMin.map(item => <Option key={item} value={item}>{item}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="AM/PM:"
                                                // name="thoigian"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'សូមបញ្ចូលព័ត៌មាននេះ!',
                                                    },
                                                ]}
                                                wrapperCol={{
                                                    span: 22,
                                                }}>
                                                <Select value={this.state.amPm} onChange={this.onChangeAMPM} >
                                                    <Option value="AM">AM</Option>
                                                    <Option value="PM">PM</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 10,
                                            span: 16,
                                        }}
                                    >
                                        {this.state.isEnableBtnSubmit &&
                                            <Button type="warrning" htmlType="submit" style={{ backgroundColor: '#ffa900', width: "120px", height: "40px"}}>
                                                បញ្ជាក់
                                            </Button>
                                        }
                                        {!this.state.isEnableBtnSubmit &&
                                            <Button disabled type="warrning" htmlType="submit" style={{ backgroundColor: '#6c757d', width: "120px", height: "40px" }}>
                                                បញ្ជាក់
                                            </Button>
                                        }
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span='9' offset='1'>
                                <img className="img-fluid" src="images/wtw/guidew.png" alt="" />
                            </Col>
                        </Row>

                    </div>
                </div>
            </MainLayout>
        );
    }
}