import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './wingToWing.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { PostApiUrl } from '../../utils/fetchApi';
import { Button, Col, Row, Select, Input } from 'antd';
import { LocalStore } from '../../utils/LocalStore/index';
const { Option } = Select;

export default class WingToWing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mmk: 0,
            wingIdNumber: "",
            type: null,
            mailSuport: "nota.shan777@gmail.com",
            chips: "",
            pageId: "104055704861817",
            appId: "272959961281350",

            yourWingID: "",
            yourTid: "",
            token: "",
            savenumber: [],
            hasSubmit: false,
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id");
        let chips = new URLSearchParams(this.props.location.search).get("chip");
        let mmk = new URLSearchParams(this.props.location.search).get("mmk");
        let token = new URLSearchParams(this.props.location.search).get("code") || 0;
        let type = new URLSearchParams(this.props.location.search).get("ty") || null;
        let source = new URLSearchParams(this.props.location.search).get("s") || "Mitdot";

        let wid = LocalStore.getInstance().read('wtw-input-wid') || "";

        let sNumber = LocalStore.getInstance().read('wtw-save-number') || '';
        (sNumber.length > 0) ? sNumber = sNumber.split(',') : sNumber = [];

        const ag = {
            w: 104055704861817,
            g: 106964708008682,
            b: 100149498735032,
            d: 102243108786262
        }
        let app = new URLSearchParams(this.props.location.search).get("a") || 'w'

        this.setState({
            isLoading: true,
            rate: mmk + " ដុល្លា ",
            userId: idUser,
            mmk: mmk,
            chips,
            type,
            source,
            pageId: ag[app],
            token,
            // yourWingID: wid,
            savenumber: sNumber
        });

        type && this.setType(type);
    }

    getWingAccount(type) {
        let token = new URLSearchParams(this.props.location.search).get("code") || 0;
        let body = {
            token,
            type: type == 'b' ? "BANK" : ""
        }

        let url = "https://wing.win777.casino/pay/acc";

        PostApiUrl(url, body, (res) => {
            // console.log("account: ", res, body)
            if (res.error === 0)
                this.setState({
                    wingIdNumber: res.data.phone
                })
        })
    }

    onCopy(str) {
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    setType(type) {
        this.setState({ type: type });
        this.getWingAccount(type);
    }

    handleChange = (a, b) => {
        // console.log("âfa", a, b);
        this.setType(a);
    }

    onChangeText = (element) => {
        // console.log("âfa", element, element.target, element.target.id, element.target.value);
        this.setState({
            [element.target.id]: element.target.value.replace(/[\u200B-\u200D\uFEFF]/g, '')
        })
    }

    setWingNumber(s) {
        this.setState({ yourWingID: s });
    }

    onComfirmClicked = () => {
        if (this.state.yourWingID.trim().length == 0) {
            toast.error("Wing ID មិនអាចអត់បំពេញបានទេ");
            return;
        }
        // else if (this.state.yourTid.trim().length == 0) {
        //     toast.error("Transaction ID មិនអាចអត់បំពេញបានទេ");
        //     return;
        // }

        var url = 'https://wing.win777.casino/pay';
        if (this.state.type == 'b') url = 'https://wing.win777.casino/pay/bank'
        let body = {
            userId: this.state.userId,
            token: this.state.token,
            accountSent: this.state.yourWingID.trim(),
            accountReceive: this.state.wingIdNumber,
            amount: this.state.mmk,
            transId: this.state.yourTid,
            source: this.state.source
        };

        PostApiUrl(url, body, (responseText) => {
            // console.log("account: ", body, responseText)
            if (responseText && responseText.error == 0) {
                toast.success("ប្រព័ន្ធកំពុងដំណើរការ!");
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.yourWingID) && sN.length < 2) {
                    sN.push(this.state.yourWingID.trim());
                } else if (!sN.includes(this.state.yourWingID)) {
                    sN.push(this.state.yourWingID.trim());
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('wtw-save-number', sN.toString());

                LocalStore.getInstance().save('wtw-input-wid', this.state.yourWingID);

                this.setState({ hasSubmit: true })

                // window.location = `/#/wtwscs?code=${this.state.token}&chip=${this.state.chips}&pd=${this.state.pageId}`;
            }
            else
                toast.error("កំហុស!");
        })
    }

    onComplete = () => {
        window.location = `/#/wtwscs?code=${this.state.token}&chip=${this.state.chips}&pd=${this.state.pageId}`;
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Wing To Wing'}>
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content wtw">
                    {this.state.type &&
                        <div className="container">
                            <p className="font-weight-bold text-center mb-4" style={{ fontSize: "16px" }}>ដាក់ប្រាក់ <span className="text-primary">{this.state.rate}</span> ចូលគណនីយអតិថិជន <span className="text-danger">{this.state.userId}</span>  </p>
                            {/* <p className="text-center mb-2">សូមផ្ញើរប្រាក់តាម <span className="text-danger font-weight-bold">{this.state.type == 'n' ? "Wing to Wing (WING ដែលមាន 9 តួអក្សរ TID)" : "Wing Bank (WING ដែលមាន 16 តួអក្សរ TID)"}</span> <br></br> ទៅគណនីយវីងខាងក្រោមនេះ:</p> */}
                            <Row className="wrraper-guide text-center" style={{ marginBottom: '10px' }}>
                                <Col span={24} align='left' style={{ color: 'red', fontWeight: 'bold' }}>
                                    ជំហានទី 1:
                                </Col>
                                <Col span={24} align='left' style={{ padding: '5px', border: '1px solid #555555' }}>
                                    បញ្ចូលលេខគណនី WingID របស់អ្នកក្នុងប្រអប់ខាងក្រោម
                                    <Input type="text" className="form-control input-ref mt-2 mb-2" placeholder="ដាក់លេខគណនីយវីងនៅខាងក្រោមនេះ" aria-label="Wing ID" aria-describedby="basic-addon1" onChange={this.onChangeText} id="yourWingID" value={this.state.yourWingID}></Input>

                                    {this.state.savenumber.length > 0 &&
                                        <Row align="middle" className="">
                                            <Col span={8} align="left">
                                                <p>ព័ត៌មានជំនួយ:</p>
                                            </Col>
                                            {this.state.savenumber.map((s) =>
                                                <Col xs={8} xl={8} align="left">
                                                    <Button style={{ width: 'auto', height: '30px', backgroundColor: '#c5dbff', marginTop: '10px', borderRadius: '6px' }} className="p-1" onClick={() => this.setWingNumber(s)}>
                                                        {s}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    }
                                </Col>
                                {!this.state.hasSubmit &&
                                    <Col span={24} align='middle' style={{ margin: '10px 0' }}>
                                        <button type="button" className="btn btn-primary" onClick={this.onComfirmClicked} style={{ background: '#00B000', borderRadius: '5px', width: '120px'}}>យល់ព្រម</button>
                                    </Col>
                                }
                                {this.state.hasSubmit &&
                                    <Col span={24} align='middle' style={{ margin: '10px 0' }}>
                                        <button type="button" className="btn btn-primary" onClick={this.onComfirmClicked} style={{ background: '#EA8C00', borderRadius: '5px', width: '120px' }}>ធ្វើបច្ចុប្បន្នភាព</button>
                                    </Col>
                                }
                                <Col span={10} offset={7} style={{ border: '2px solid #DBDBDB' }}></Col>
                            </Row>

                            {this.state.hasSubmit &&
                                <Row className="wrraper-guide text-center">
                                    <Col span={24} align='left'>
                                        <p><span style={{ color: 'red', fontWeight: 'bold' }}> ជំហានទី 2: </span><span style={{ color: '#254EE2' }}>សូមផ្ទេរប្រាក់ទៅកាន់ព័ត៌មានគណនីទទួលខាងក្រោម</span></p>
                                    </Col>
                                    <Col span={24} align='left'>
                                        <table className="table table-bordered mb-3">
                                            <tbody>
                                                <tr>
                                                    <td className="text-left font-weight-bold">ប្រភេទគណនីយ</td>
                                                    <td className="font-weight-bold">
                                                        <Select defaultValue={this.state.type} onChange={this.handleChange} style={{ color: 'red' }}>
                                                            <Option value="n" >WING ដែលមាន 9 តួអក្សរ TID</Option>
                                                            <Option value="b" >WING ដែលមាន 16 តួអក្សរ TID</Option>
                                                        </Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bold">ចំនួនទឹកប្រាក់</td>
                                                    <td className="font-weight-bold">
                                                        <p className="text-left text-danger">{"$ " + this.state.mmk} </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bold">លេខគណនីយវីង</td>
                                                    <td className="font-weight-bold position-relative">
                                                        <p className="text-left text-danger">{this.state.wingIdNumber} </p>
                                                        <p className="text-right position-absolute" style={{ right: "10px", top: "0px" }}><button type="button" className="btn btn-primary" onClick={() => this.onCopy(this.state.wingIdNumber)} style={{ backgroundColor: '#F3F3F3', color: 'black', borderRadius: '10px' }}>Copy</button></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>

                                    <p><span className="font-weight-bold text-danger"><i className="fas fa-exclamation-triangle"></i> ចំណាំ:</span> សូមពិនិត្យហើយ​ Copy គណនីយវីងនៅខាងក្រោមមុនពេលផ្ញើរប្រាក់។ ដោយសារតែគណនីយវីងរបស់យើងខ្ញុំតែងតែផ្លាស់ប្ដូរជាញឹកញាប់។​ប្រសិនបើអ្នកផ្ញើរមកខុសគណនីយដែលបានដាក់បង្ហាញនោះអ្នកមិនមិនអាចទទួលបានកាក់នោះទេ។ហើយពួកយើងនឹងមិនទទួលខុសត្រូវទ្បើយ.</p>

                                    <p style={{ margin: '5px' }}><span className="font-weight-bold text-danger"><i className="fas fa-exclamation-triangle"></i> </span>បន្ទាប់ពីផ្ទេរប្រាក់រួច សូមចុច បញ្ជាក់ ដើម្បីបញ្ចប់ប្រតិបត្តិការ</p>
                                    <Col span="24" align="middle" className="mt-2">

                                        <button type="button" className="btn btn-primary" onClick={this.onComplete} style={{ width: '100px', fontWeight: 'bold' }}>រួចរាល់</button>
                                    </Col>
                                </Row>
                            }
                        </div>
                    }
                    {!this.state.type &&
                        <div className="container">
                            <p className="text-center mb-2" style={{ fontSize: "18px", fontWeight: "bold" }}>សូមជ្រើសរើសប្រភេទ គណនី WING ដែលត្រឹមត្រូវដើម្បីជៀសវាងមានកំហុសក្នុងប្រតិបត្តិការ</p>

                            <Row className="row mt-3" align="middle">
                                <Col className="" span='12' align="middle">
                                    <Button style={{ backgroundColor: '#009ad5', width: '90%', height: '150px' }} onClick={() => this.setType('n')}>
                                        <p className="font-weight-bold" style={{ color: '#d5cd00', fontSize: '18px' }}>WING ដែលមាន <br></br>9 តួអក្សរ TID</p>
                                        <p>(ឧទាហរណ៍: EGX898234)</p>
                                    </Button>
                                </Col>

                                <Col className="" span='12' align="middle">
                                    <Button style={{ backgroundColor: '#009ad5', width: '90%', height: '150px' }} onClick={() => this.setType('b')}>
                                        <p className="font-weight-bold" style={{ color: '#d5cd00', fontSize: '18px' }}>WING ដែលមាន <br></br>16 តួអក្សរ TID</p>
                                        <p>(ឧទាហរណ៍: <br></br>000234522243A3GL)</p>
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="mt-3" style={{ border: "1px solid", padding: "10px", margin: "10px" }} align="middle">
                                <Col align="middle" span='24'>
                                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>សេចក្តីណែនាំ៖</p>
                                    <p>ចូលទៅក្នុង App <span style={{ fontSize: "18px", fontWeight: "bold", color: "#28a745" }}>WingBank</span> ដើម្បីពិនិត្យមើលគណនី WING របស់អ្នកដើម្បីមើលថាតើ TID មាន 9 តួអក្សរ ឬ 16 តួអក្សរ។ បន្ទាប់មកចុចត្រលប់មកទីនេះវិញ ហើយជ្រើសរើសគណនី WING ដែលត្រឹមត្រូវដើម្បីធ្វើការទិញកាក់</p>
                                </Col>
                                <Col span='24' align="middle" style={{ marginTop: "10px" }}>
                                    <img src="images/wtw/ex160.png" width="90%" />
                                </Col>
                                <Col span='24' align="middle">
                                    <p style={{ fontSize: "20px", color: "red" }}>នេះគឺជាឧទាហរណ៍នៃគណនី Wing ដែលមាន TID 16 តួអក្សរ</p>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </MainLayout>
        );
    }
}