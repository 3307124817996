import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './mtm.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row, Image } from 'antd';
import { LocalStore } from '../../utils/LocalStore/index';

export default class MTM extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            accountList: [],
            source: "wepay",
            phoneRecive: {},
            savenumber: [],
            nid: '',
            hasNext: false
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"

        let sNumber = LocalStore.getInstance().read('mtm-save-number') || '';
        (sNumber.length > 0) ? sNumber = sNumber.split(',') : sNumber = [];

        this.getAccount();

        this.setState({
            isLoading: true,
            rate: mmk + ".00", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            savenumber: sNumber
        });

        this.setTabIndex(0)
    }

    getAccount() {
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let data = {
            token: token,
        }

        let url = "https://maya.tongits.club/account";
        PostApiUrl(url, data, (res) => {
            console.log("account: ", res, data)
            if (res && res.error === 0 && res.data && res.data.length > 0)
                this.setState({
                    accountList: res.data,
                    phoneRecive: res.data[0]
                })
        })
    }

    onTextChange(e) {
        this.setState({
            nid: e.target.value
        })
    }

    setTabIndex(index) {
        this.setState({ tabIndex: index })
    }

    onCopy(str) {
        // navigator.clipboard.writeText(str);

        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    onChangeAccountClick() {
        this.getAccount();
    }

    showInputNumber() {
        window.location = '/#/mtmload' + this.props.location.search + '&ne=inputnumber';
    }

    onShowMaya() {
        if (this.state.nid.length == 0) {
            toast.error("Pakilagay ang iyong Maya number");
            return;
        }
        if (this.state.nid.length != 11 || !this.state.nid.startsWith('09')) {
            toast.error("Maya number includes only 11 numbers starting with 09");
            return;
        }

        let data = {
            token: this.state.token,
            accountSent: this.state.nid,
            source: this.state.source
        }

        let url = "https://maya.tongits.club/mobile";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (!res || res.error !== 0) {
                toast.error('error')
            } else {
                // this.setTabIndex(2);
                toast.success('Magpatuloy')
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.nid) && sN.length < 2) {
                    sN.push(this.state.nid);
                } else if (!sN.includes(this.state.nid)) {
                    sN.push(this.state.nid);
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('mtm-save-number', sN.toString());

                this.setState({ hasNext: true });
            }
        })
    }

    onShowCheckPage() {
        window.location = '/#/mtmscs' + this.props.location.search;
    }

    showInputTid() {
        if (this.state.nid.length == 0) {
            toast.error("Pakilagay ang iyong Maya number");
            return;
        }
        if (this.state.nid.length != 11 || !this.state.nid.startsWith('09')) {
            toast.error("Maya number includes only 11 numbers starting with 09");
            return;
        }

        let data = {
            token: this.state.token,
            accountSent: this.state.nid,
            source: this.state.source
        }

        let url = "https://maya.tongits.club/mobile";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (!res || res.error !== 0) {
                toast.error('error')
            } else {
                // this.setTabIndex(2);
                toast.success(res.message)
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.nid) && sN.length < 2) {
                    sN.push(this.state.nid);
                } else if (!sN.includes(this.state.nid)) {
                    sN.push(this.state.nid);
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('mtm-save-number', sN.toString());

                window.location = '/#/mtminputtid' + this.props.location.search + '&nid=' + this.state.nid;
            }
        })
    }

    setMayaNumber(s) {
        this.setState({ nid: s });
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Maya To Maya'} color="maya">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">₱ {this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                        {!this.state.hasNext &&
                            <>
                                {/* <p className="text-center mb-2" style={{ fontSize: "16px" }}>Mangyaring magpadala lamang ng pera sa account na ipinapakita sa ibaba:</p> */}
                                <div style={{ color: 'black', fontWeight: 'bold', backgroundColor: '#75EEA5', padding: '6px', width: '105px' }}>HAKBANG 1: </div>
                                <div style={{ fontSize: "14px", padding: "10px", padding: '10px' }}>
                                    <p style={{ fontWeight: 'bold' }}>Ang iyong Maya number:</p>
                                    <input type="number" pattern="\d*" className="form-control input-ref" placeholder="Ilagay ang iyong Maya number dito" onChange={(e) => this.onTextChange(e)} value={this.state.nid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                                    {this.state.savenumber.length > 0 &&
                                        <Row align="middle" className="">
                                            <Col span={8} align="left">
                                                <p>Suhestiyon:</p>
                                            </Col>
                                            {this.state.savenumber.map((s) =>
                                                <Col xs={8} xl={8} align="left">
                                                    <Button style={{ width: 'auto', height: '30px', backgroundColor: '#c5dbff', marginTop: '10px', borderRadius: '6px' }} className="p-1" onClick={() => this.setMayaNumber(s)}>
                                                        {s}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    }
                                </div>
                                <Row align='middle'>
                                    <Col span={24} align="middle">
                                        <Button size='large' style={{ width: '100%', borderRadius: "2px", color: '#fff', backgroundColor: "#0daf01", fontSize: "14px", fontWeight: "bold", padding: '10px 20px 30px 20px' }} onClick={() => this.onShowMaya()}>Next</Button>
                                    </Col>
                                </Row>

                            </>
                        }

                        {this.state.hasNext &&
                            <>
                                <div style={{ color: 'black', fontWeight: 'bold', backgroundColor: '#75EEA5', padding: '6px', width: '105px' }}>HAKBANG 2: </div>
                                <p style={{ fontWeight: 'bold', margin: '10px 3px' }}>Send money to this Maya account:</p>
                                <Row style={{ border: '2px solid #44908F', padding: '5px' }} align="middle">
                                    <Col span={20} offset={2} style={{ marginTop: '5px' }}>
                                        <Row style={{ width: '100%', height: 'auto' }}>
                                            <Col span={24} align='middle' style={{ backgroundColor: "#fff", color: '#000', fontWeight: 'bold', padding: '6px' }}>
                                                Send money
                                            </Col>
                                            <Col span={24} align='middle'>
                                                <Row align='middle' style={{}}>
                                                    <Col span={18} align='left' style={{ backgroundColor: '#F4F4F4', padding: '5px' }}>
                                                        <Row>
                                                            <Col span={20} >
                                                                <div style={{ padding: '1px 10px', color: '#4AA25C' }}>
                                                                    Recipient
                                                                </div>
                                                                <div style={{ padding: '1px 10px', color: '#000' }}>
                                                                    {this.state.phoneRecive.phone}
                                                                </div>
                                                            </Col>
                                                            <Col span={4} align='middle'>
                                                                <div>
                                                                    <a onClick={() => this.onCopy(this.state.phoneRecive.phone)} ><img src="images/maya/copy.png"></img></a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={6} align='right'>
                                                        <button onClick={() => this.onChangeAccountClick()} style={{ width: '70px', maxwidth: '100%', fontSize: '12px', padding: '10px' }} type="button" className="btn btn-warning">Change Account</button>
                                                    </Col>
                                                </Row>

                                                <Row align='middle' style={{ marginTop: '10px' }}>
                                                    <Col span={24} align='left' style={{ backgroundColor: '#F4F4F4', padding: '5px' }}>
                                                        <Row>
                                                            <Col span={24} >
                                                                <div style={{ padding: '1px 10px', color: '#4AA25C' }}>
                                                                    Account name:
                                                                </div>
                                                                <div style={{ padding: '1px 10px', color: '#000' }}>
                                                                    {this.state.phoneRecive.name}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row align='middle' style={{ marginTop: '10px' }}>
                                                    <Col span={24} align='left' style={{ backgroundColor: '#F4F4F4', padding: '5px' }}>
                                                        <Row>
                                                            <Col span={24} >
                                                                <div style={{ padding: '1px 10px', color: '#4AA25C' }}>
                                                                    Amount
                                                                </div>
                                                                <div style={{ padding: '1px 10px', color: '#000' }}>
                                                                    ₱ {this.state.rate}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row align="middle" style={{ marginTop: "10px", marginBottom: "" }}>
                                    <Col span={24} align='middle'>
                                        <Button size='large' style={{ width: '100%', borderRadius: "2px", color: '#fff', backgroundColor: "#0daf01", fontSize: "14px", fontWeight: "bold", padding: '10px 20px 30px 20px' }} onClick={() => this.onShowCheckPage()}>Continue after sending money</Button>
                                    </Col>
                                </Row>

                                <div style={{ fontSize: "16px", padding: "10px" }}>
                                    <p className="font-weight-bold text-danger">Pay Attention!</p>

                                    <p className="ml-2">👉  - Only user Maya account to send money to our Maya accounts.</p>
                                    <p className="ml-2">👉  - Click button “Change account” when the current recipient account reaches limit.</p>
                                </div></>
                        }
                    </div>

                </div>
            </MainLayout >
        );
    }
}