import React, { Component } from 'react';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
    async componentDidMount() {
        console.log('componentDidMount');
    }

    render() {
        return (
            <div id={"app-main"} className="{App}">
                <Routes />
            </div>
        );
    }
}

export default App;
