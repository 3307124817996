
import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { startTransition } from "react";

export default class GcashSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            pageId: "106100838242732",
            appId: "272959961281350",
            chips: "5,000",
        };
    }

    componentDidMount() {
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0;
        this.setState({
            chips,
        });
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <div className="wrapper-tab3 text-center" style={{ height: "450px" }}>
                            <h2 className="text-success">Salamat!</h2>
                            <p className="font-weight-bold">Nakumpleto mo na ang iyong transaksyon.</p>
                            <p className="font-weight-bold">Darating ang chips sa iyong account sa loob ng humigit-kumulang 30 min.</p>
                            <br></br><br></br><br></br>
                            <p className="font-weight-bold text-primary"><i>Tandaan: Kung hindi ka makakatanggap ng mga chips sa loob ng 30 minuto, Mangyaring makipag-ugnayan sa admin para sa suporta.</i></p>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

