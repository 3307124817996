import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToBank.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'

export default class GcashToBank extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            accountName: "",
            accountNumber: "",
            acountReceipt: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            tid: ""
        };
    }
    componentDidMount() {

        GetApiUrl("https://gcash-mail.tongits.club/account", (res) => {
            console.log(res)
            let data = res.data;
            if (res.error === 0)
                this.setState({
                    // set state when have data
                    accountName: data.accountName,
                    accountNumber: data.accountNumber,
                    acountReceipt: res.mail,
                })
        })

        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0

        this.setState({
            isLoading: true,
            rate: mmk + " PHP = " + chips,
            userId: idUser,
            mmk,
            chips,
        });

        this.setTabIndex(0)
    }

    onTextChange(e) {
        this.setState({
            tid: e.target.value
        })
    }

    onSubmit() {

        if (this.state.tid.length <= 0) {
            toast.error("Ref Number don't empty")
            return;
        }

        let data = {
            userId: this.state.userId,
            accountReceive: this.state.acountReceipt,
            amount: this.state.mmk,
            transId: this.state.tid,
            source: "wepay"
        }

        PostApiUrl("https://gcash-mail.tongits.club", data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message)
            } else {
                this.setTabIndex(2);
                toast.success(res.message)
            }
        })

    }

    setTabIndex(index) {
        this.setState({ tabIndex: index })

        const myElement = document.getElementById('tabList');
        for (let i = 0; i < myElement.children.length; i++) {
            if (i < index)
                setTimeout(() => {
                    let item = myElement.children[i];
                    item.className += " item-success";
                }, 300);
        }
    }

    onCopy(str) {
        // navigator.clipboard.writeText(str);

        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Union Bank'}>
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content">
                    <div className="container">
                        <p className="font-weight-bold text-center mb-2" style={{ fontSize: "16px" }}>Purchase <span className="text-danger">{this.state.rate}</span> chips <br></br> to user <span className="text-primary">{this.state.userId}</span>  </p>

                        {/* <a href='#/test'>test</a> */}
                        <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setTabIndex(index)}>
                            <TabList id="tabList">
                                <Tab disabled={true}><span className="item-tab">1. Bank Transfer</span></Tab>
                                <Tab disabled={true}><span className="item-tab">2. Confirm <br></br> Ref. No.</span></Tab>
                                <Tab disabled={true}><span className="item-tab">3. Complete</span></Tab>
                            </TabList>

                            <TabPanel>
                                <p className="text-center mb-3" style={{ fontSize: "13px" }}>Please choose <span className="font-weight-bold text-danger">"Bank Transfer"</span> and then  <span className="font-weight-bold text-danger">"Union bank"</span> transfer to our Union account below:</p>
                                {/* {this.state.isLoading && <ReactLoading type={'balls'} color={'#54f'} height={'20px'} width={'20px'} />} */}
                                <div className="wrraper-guide text-center">
                                    <div className="wrapper-img-guide">
                                        <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/guide.png" alt="" />
                                        <img className="img-fluid img-gif" src="images/giftsy.gif" alt="" />
                                    </div>
                                    <p className="mt-3"></p>
                                    <table className="table table-bordered mb-2">
                                        <tbody>
                                            <tr>
                                                <td className="text-left font-weight-bold">Acount name</td>
                                                <td className="text-right text-danger font-weight-bold" onClick={() => this.onCopy(this.state.accountName)}><p>{this.state.accountName} <button type="button" className="btn btn-primary btn-sm ml-2">Copy</button></p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bold">Acount number</td>
                                                <td className="text-right text-danger font-weight-bold" onClick={() => this.onCopy(this.state.accountNumber)}><p>{this.state.accountNumber} <button type="button" className="btn btn-primary btn-sm ml-2">Copy</button></p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bold">Send receipt to</td>
                                                <td className="text-right text-danger font-weight-bold" onClick={() => this.onCopy(this.state.acountReceipt)}><p>{this.state.acountReceipt} <button type="button" className="btn btn-primary btn-sm ml-2">Copy</button></p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><span className="font-weight-bold"><i className="fas fa-exclamation-triangle text-warning"></i> Pay attention:</span> The mail <span className="text-danger font-weight-bold">{this.state.acountReceipt}</span>  <br></br> is always obliged for your transaction.</p>
                                </div>
                                <div className="text-center mb-3 mt-3">
                                    <button type="button" className="btn btn-primary btn-custom" onClick={() => this.setTabIndex(1)} >Continue</button>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="wrapper-tab2">
                                    {/* <iframe width="100%" height="240" src="https://www.youtube.com/embed/r7uHSjHAMqc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    <div className="text-center mt-3 mb-3">
                                        <img className="img-fluid img-gif" src="images/guide2.jpg" alt="" />
                                    </div> 
                                    <p className="text-center mt-2 mb-2">Please enter the <span className="text-danger font-weight-bold">Ref Number</span> of your transaction below:</p>
                                    <input type="number" className="form-control input-ref" placeholder="Enter Ref Number here" onChange={(e) => this.onTextChange(e)} value={this.state.tid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                                    <div className="two-button text-center mt-4 mb-4">
                                        <button type="button" className="btn btn-secondary mr-1 btn-custom" onClick={() => this.setTabIndex(0)} >Back</button>
                                        <button type="button" className="btn btn-primary ml-1 btn-custom" onClick={() => this.onSubmit()} >Confirm</button>
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="wrapper-tab3 text-center">
                                    <h3 className="text-danger">Congrats!</h3>
                                    <p className="font-weight-bold">You've completed your transaction.</p>
                                    <p className="font-weight-bold"><span className="text-primary">{this.state.chips}</span> chips will arrive your account <br></br> in about <span className="text-primary">5</span> mins.</p>
                                    <br></br><br></br><br></br><br></br><br></br>
                                    <p>Tips: Save our Bank account in your Gcash app to transfer rapidly next time</p>
                                    <img className="img-fluid mt-3" style={{ maxWidth: "80%", paddingBottom: "10px" }} src="images/guide3.jpg" alt="" />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </MainLayout>
        );
    }
}