import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row } from 'antd';
import { LocalStore } from '../../utils/LocalStore/index';

export default class GtgInputNumber extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            accountList: [],
            source: "wepay",
            nid: "",
            savenumber: []
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"
        let sNumber = LocalStore.getInstance().read('gtg-save-number') || '';
        (sNumber.length > 0) ? sNumber = sNumber.split(',') : sNumber = [];
        this.setState({
            isLoading: true,
            rate: mmk + " PHP", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            savenumber: sNumber
        });

    }

    onTextChange(e) {
        this.setState({
            nid: e.target.value
        })
    }

    onSubmit() {

        if (this.state.nid.length != 11 || !this.state.nid.startsWith('09')) {
            toast.error("Gcash number includes only 11 numbers starting with 09");
            return;
        }

        let data = {
            token: this.state.token,
            accountSent: this.state.nid,
            source: this.state.source
        }

        // let url = "https://gcash.ph.notagame.club";
        let url = "https://gcash.tongits.club/mobile";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message)
            } else {
                // this.setTabIndex(2);
                toast.success(res.message)
                let sN = [...this.state.savenumber];
                if (!sN.includes(this.state.nid) && sN.length < 3) {
                    sN.push(this.state.nid);
                } else if (!sN.includes(this.state.nid)) {
                    sN.push(this.state.nid);
                    sN = sN.slice(1);
                }
                console.log('sN.toString()', sN.toString())
                LocalStore.getInstance().save('gtg-save-number', sN.toString());

                window.location = '/#/gtgload' + this.props.location.search.replaceAll('&ne=inputnumber', '') + '&ne=result&pne=' + this.state.nid;
            }
        })
    }

    setGcashNumber(s) {
        this.setState({ nid: s });
    }

    render() {
        console.log('savenumber: ', this.state.savenumber);
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">{this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                        <p className="text-center mb-2 font-weight-bold" style={{ fontSize: "16px" }}>Hindi pa matagumpay ang inyong transaksyon sa system.</p>
                        <p className="text-center mb-2 text-danger font-weight-bold" style={{ fontSize: "16px" }}>Ilagay ang iyong GCASH number(Na inyong ginamit sa pagpapadala ng pera) sa kahon sa ibaba:</p>

                        <div style={{ fontSize: "16px", padding: "10px" }}>
                            <input type="number" className="form-control input-ref" placeholder="I-tap upang mailagay ang Gcash number" onChange={(e) => this.onTextChange(e)} value={this.state.nid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                            {this.state.savenumber.length > 0 &&
                                <Row align="center" className="mt-4">
                                    <Col span={24} align="center">
                                        <p>Ginamit na Gcash accounts:</p>
                                    </Col>
                                    {this.state.savenumber.map((s) =>
                                        <Col xs={24} xl={8} align="center">
                                            <Button style={{ width: '170px', height: '40px', backgroundColor: '#b8bbc0', marginTop: '10px', borderRadius: '6px' }} className="p-1" onClick={() => this.setGcashNumber(s)}>
                                                <i class="fa fa-phone-square mr-2" aria-hidden="true"> </i>{s}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            }

                            <div className="text-center mt-4 mb-4">
                                <Button className="font-weight-bold" onClick={() => this.onSubmit()} style={{ backgroundColor: "#0eaf01", color: 'white', height: '50px', width: '200px', borderRadius: '5px', fontSize: '16px' }}>IPADALA</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}