import React, { Component } from 'react'
import './footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    render() {
        if (this.props.color === "gcash")
            return (
                <footer className="footer-page" style={{ backgroundColor: "#0071ba" }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small">Makipag ugnayan sa pamamagitan ng messager kung may problema:</p>
                        {/* <p className="font-weight-bold font-size-medium-small">to contact us via Messenger:</p> */}
                    </div>
                </footer>
            );
        else if (this.props.color === "dragon") {
            return (
                <footer className="footer-page" style={{ backgroundColor: "#c3121a" }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small">If you have any problem, don't hesitate </p>
                        <p className="font-weight-bold font-size-medium-small">to contact us via Messenger:</p>
                    </div>
                </footer>
            );
        }
        else if (this.props.color === "wave") {
            return (
                <footer className="footer-page" style={{ backgroundColor: "#feee02" }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small text-black">အကယ်၍ ငွေလွဲခြင်း၌ တစ်စုံတရာ အခက်အခဲရှိပါက၊</p>
                        <p className="font-weight-bold font-size-medium-small text-black">ဂိမ်း အက်ဒမင်များထံသို့ ဆက်သွယ်မေးမြန်းနိုင်ပါသည်။</p>
                    </div>
                </footer>
            );
        } else if (this.props.color === 'winginfo') {
            return (
                <footer className="footer-page" style={{ backgroundColor: "#a9cb37" }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small">ប្រសិនបើអ្នកមានបញ្ហានៅពេលទិញកាក់ សូមទាក់ទងទំព័រហ្វេសបុករបស់យើងភ្លាមៗសម្រាប់ការដោះស្រាយរហ័ស៖</p>
                    </div>
                </footer>
            );
        } else if (this.props.color === 'maya') {
            return (
                <footer className="footer-page" style={{ backgroundColor: "#000000", color: '#75EEA5' }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small">Makipag ugnayan sa pamamagitan ng messager kung may problema:</p>
                    </div>
                </footer>
            );
        }
        else {
            return (
                <footer className="footer-page" style={{ backgroundColor: "#A9CB37" }}>
                    <div className="footer-text-powerby container" >
                        <p className="line1 font-size-medium-small">ចុច Messager ដើម្បីទាក់ទងទៅ admin ពេលលោកអ្នកមានបញ្ហា </p>
                    </div>
                </footer>
            );
        }
    }
}

export default (Footer)