import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './gcashToGcash.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row } from 'antd';
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "../../utils/AnimatedProgressProvider.js";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";

export default class GtGLoading extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            source: "wepay",
            paramNextPage: '',
            nextpage: ''
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"
        let nextpage = new URLSearchParams(this.props.location.search).get("ne") || "inputnumber"
        let paramNextPage = new URLSearchParams(this.props.location.search).get("pne") || ""

        this.setState({
            isLoading: true,
            rate: mmk + " PHP", // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            paramNextPage,
            nextpage
        });
        let t = 20;
        if (nextpage == "inputnumber") {
            t = 20;
            this.setState({ timer: t });
            setTimeout(() => {
                let data = {
                    token: this.state.token
                }
                let url = "https://gcash.tongits.club/mobile-check";
                PostApiUrl(url, data, (res) => {
                    if (!res || res.error != 0) {
                        window.location = '/#/gtginputnum' + this.props.location.search.replaceAll('&ne=inputnumber', '');
                        toast.error('res.message')
                    } else {
                        if (res.data && res.data.transactionId) {
                            window.location = '/#/gtgsuccess' + this.props.location.search.replaceAll('&ne=result', '').replaceAll(`&pne=${this.state.paramNextPage}`, '') + '&tid=' + res.data.transactionId;
                        } else {
                            window.location = '/#/gtginputnum' + this.props.location.search.replaceAll('&ne=inputnumber', '');
                        }
                    }
                })
            }, t * 1000);
        }
        else if (nextpage == "result") {
            t = 20;
            this.setState({ timer: t });
            setTimeout(() => {
                this.checkMobile();
            }, t * 1000);
        }
    }

    checkMobile() {
        let data = {
            token: this.state.token,
            accountSent: this.state.paramNextPage,
        }

        let url = "https://gcash.tongits.club/mobile-check";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (!res || res.error != 0) {
                window.location = '/#/gtginputtid' + this.props.location.search.replaceAll('&ne=result', '').replaceAll(`&pne=${this.state.paramNextPage}`, '') + '&nid=' + this.state.paramNextPage;
                // toast.error(res.message)
            } else {
                if (res.data && res.data.transactionId) {
                    window.location = '/#/gtgsuccess' + this.props.location.search.replaceAll('&ne=result', '').replaceAll(`&pne=${this.state.paramNextPage}`, '') + '&tid=' + res.data.transactionId;
                } else {
                    window.location = '/#/gtginputtid' + this.props.location.search.replaceAll('&ne=result', '').replaceAll(`&pne=${this.state.paramNextPage}`, '') + '&nid=' + this.state.paramNextPage;
                }
            }
        })
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Gcash To Gcash'} color="gcash">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">{this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>

                        <Row align='center'>
                            <Col span={24} align='center' style={{ color: "red", marginBottom: "20px", fontWeight: "bold", textAlign: 'center', fontSize: '16px' }}>
                                <p>Maaaring maghintay.</p>
                                <p>Pinoproseso pa ang inyong transaksyon!</p>
                            </Col>
                            <div style={{ width: "220px" }}>
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={100}
                                    duration={this.state.timer}
                                    easingFunction={easeQuadInOut}
                                >
                                    {value => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbar
                                                value={value}
                                                text={`${roundedValue}%`}
                                                /* This is important to include, because if you're fully managing the
                                          animation yourself, you'll want to disable the CSS animation. */
                                                styles={buildStyles({
                                                    pathTransition: "none",
                                                    textColor: "red",
                                                    pathColor: "turquoise",
                                                    trailColor: this.state.nextpage == 'result' ? 'green' : "gold",
                                                })}
                                            />
                                        );
                                    }}
                                </AnimatedProgressProvider>
                            </div>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}