import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './mtm.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { Button, Col, Row } from 'antd';

export default class GtgSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "106100838242732",
            appId: "272959961281350",
            token: "",
            source: "wepay"
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let source = new URLSearchParams(this.props.location.search).get("s") || "wepay"
        let tid = new URLSearchParams(this.props.location.search).get("tid") || ""

        this.setState({
            isLoading: true,
            rate: mmk, // + chips,
            userId: idUser,
            mmk,
            chips,
            token: token,
            source: source,
            tid
        });
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Maya To Maya'} color="maya">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content gtg">
                    <div className="container">
                        <p className="text-center mb-2" style={{ fontSize: "16px" }}>Bumili ka ng <span className="font-weight-bold text-danger">₱ {this.state.rate}</span> para sa <span className="font-weight-bold text-danger">ID {this.state.userId}</span>  </p>
                        <Row align='middle' style={{ fontWeight: 'bold', fontSize: "18px", backgroundColor: '#E3FAE7', color: '#4AA25C', padding : '20px', textAlign: 'center', margin: '20px 0' }}>
                            <p>Chip will be added in 15 minutes. Please back to game and check!</p>
                        </Row>

                        <p className="text-center mb-2 text-danger" style={{ fontSize: "16px" }}>*if you don’t receive the chips in 15 minutes, please contact admin via Messenger for support.</p>

                    </div>
                </div>
            </MainLayout>
        );
    }
}