import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'
import { LocalStore } from '../../utils/LocalStore/index';
import ReactLoading from 'react-loading';

export default class DragonPay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            userId: "",
            rate: "",
            mmk: "",
            chips: "5,000",

            email: "",

            emailErr: "",
            onWaiting: false,

            pageId: "106100838242732",
            appId: "272959961281350"
        };
    }
    componentDidMount() {

        let idUser = new URLSearchParams(this.props.location.search).get("id")
        let chips = new URLSearchParams(this.props.location.search).get("chip")
        let mmk = new URLSearchParams(this.props.location.search).get("mmk")
        let token = new URLSearchParams(this.props.location.search).get("code")

        this.setState({
            isLoading: false,
            onWaiting: !this.checkValidation(LocalStore.getInstance().read('dp-input-email') || ""),
            rate: mmk + " PHP = " + chips,
            userId: idUser,
            mmk: mmk,
            chips,
            email: LocalStore.getInstance().read('dp-input-email') || ""
        });
    }

    onNext() {
        this.setState({onWaiting: true})
        let mmk = new URLSearchParams(this.props.location.search).get("mmk")
        let token = new URLSearchParams(this.props.location.search).get("code")

        let data = {
            token: token,
            amount: mmk,
            email: this.state.email,
            source: "wepay"
        }
        // let url = "https://gcash.ph.notagame.club/dp";
        let url = "https://gcash.tongits.club/dp";
        
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message);
                this.setState({onWaiting: false})
            } else {
                // toast.success(res.message)    
                LocalStore.getInstance().save('dp-input-email', this.state.email);
                window.location.href = res.data;
            }
        })
    }

    checkValidation(email) {
        let emailErr = "";
        var patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!patternEmail.test(email)) {
            emailErr = "Please enter valid email address."
        } else {
            emailErr = ""
        }
        this.setState({ emailErr: emailErr });
        return (emailErr.length == 0)
    }

    onTextChange(e) {
        console.log(e);
        this.setState({
            [e.target.id]: e.target.value,
            onWaiting: !this.checkValidation(e.target.value)
        })
    }

    onNextClicked() {
        this.onNext();
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Dragon Pay'} color="dragon">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content dp">
                    <div className="container">
                        <p className="font-weight-bold text-center mb-3" style={{ fontSize: "17px" }}>Purchase <span className="text-danger">{this.state.rate}</span> chips <br></br> to user <span className="text-primary">{this.state.userId}</span>  </p>
                        <div className="main-contain">
                            <p className="font-weight-bold ml-2" style={{ fontSize: "16px" }}>Dragon Pay</p>
                            <hr></hr>

                            <p className="mt-2">Email:</p>
                            <input type="text" value={this.state.email} className="glc-input-ref" id="email" placeholder="Enter email address" aria-describedby="basic-addon1" onChange={(e) => this.onTextChange(e)}></input>
                            <p style={{ color: "#ff002f" }}>{this.state.emailErr}</p>

                            {this.state.onWaiting ?
                                <div className="two-button text-center mt-4 mb-4">
                                    <button disabled type="button" className="btn btn-primary ml-1 glc-btn-next" onClick={() => this.onNextClicked()} >
                                        NEXT
                                    </button>
                                    {/* <div style={{ position: "absolute", top: "48%", left: "48%" }}>
                                        <ReactLoading type={'spinningBubbles'} color={'#ff8700'} height={'20px'} width={'20px'} />
                                    </div> */}
                                </div> :
                                <div className="two-button text-center mt-4 mb-4">
                                    <button type="button" className="btn btn-primary ml-1 glc-btn-next" onClick={() => this.onNextClicked()} >
                                        NEXT
                                    </button>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </MainLayout>
        );
    }
}