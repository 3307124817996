import React, { Component } from "react";
import MainLayout from "../../components/Layout/index";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import './WaveToWave.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GetApiUrl, PostApiUrl } from '../../utils/fetchApi'

export default class WaveToWave extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
            lbTitle: 'Purchase here!!',
            userId: "",
            rate: "",
            mailSuport: "",
            chips: "5,000",
            pageId: "104595298026281",
            appId: "272959961281350",
            tid: "",
            token: "",
            accountList: [],
            isComplete: false,
            waveAccount: ""
        };
    }
    componentDidMount() {
        let idUser = new URLSearchParams(this.props.location.search).get("id") || 0
        let chips = new URLSearchParams(this.props.location.search).get("chip") || 0
        let mmk = new URLSearchParams(this.props.location.search).get("mmk") || 0
        let token = new URLSearchParams(this.props.location.search).get("code") || 0

        this.getAccount();

        this.setState({
            isLoading: true,
            rate: mmk + " ကျပ်ဖိုး = " + chips,
            userId: idUser,
            mmk,
            chips,
            token: token
        });

        this.setTabIndex(0)
    }

    getAccount() {
        let token = new URLSearchParams(this.props.location.search).get("code") || 0
        let data = {
            token: token,
        }

        let url = "https://wave.shanplay.net/account";
        PostApiUrl(url, data, (res) => {
            console.log("account: ", res, data)
            if (res.error === 0)
                this.setState({
                    accountList: res.data
                })
        })
    }

    onTextChange(e) {
        this.setState({
            tid: e.target.value
        })
    }

    onSubmit() {
        // this.setACtiveComplete();
        // return

        if (this.state.tid.length <= 0) {
            toast.error("TID don't empty")
            return;
        }

        let data = {
            userId: this.state.userId,
            accountSent: this.state.waveAccount,
            accountReceive: this.state.acountReceipt,
            amount: this.state.mmk,
            transId: this.state.tid,
            source: "wave"
        }

        let url = "https://wave.shanplay.net";
        PostApiUrl(url, data, (res) => {
            console.log(res)
            if (res.error !== 0) {
                toast.error(res.message)
            } else {
                this.setACtiveComplete();
                toast.success(res.message)
            }
        })
    }

    setACtiveComplete() {
        this.setState({ isComplete: true })
    }

    setTabIndex(index) {
        if (this.isComplete) return;
        this.setState({ tabIndex: index })

        const myElement = document.getElementById('tabList');
        for (let i = 0; i < myElement.children.length; i++) {
            if (i < index)
                setTimeout(() => {
                    let item = myElement.children[i];
                    item.className += " item-success";
                }, 300);
        }
    }

    onCopy(str) {
        // navigator.clipboard.writeText(str);

        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(str);
            toast.info(`Coppied ${str} to clipboard!`)
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = str;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                toast.info(`Coppied ${str} to clipboard!`)
            });
        }
    }

    onTextChangewaveAccount(e) {
        if (e.target.value.length <= 11) {
            this.setState({
                waveAccount: e.target.value
            })
        }
    }

    onContinueStep3() {
        if (this.state.waveAccount.length <= 0) {
            toast.error("Wave Acoount don't empty!")
        }
        else if (this.state.waveAccount.trim().length < 9 || this.state.waveAccount.trim().length > 11) {
            toast.error("The phone number must have 9-11 characters!")
        }
        else if (this.state.waveAccount.trim().substring(0, 2) !== '09') {
            toast.error("The phone number must include 9-11 numbers and start with 09")
            return;
        } else {
            this.setTabIndex(2)
        }
    }

    render() {
        return (
            <MainLayout {...this.props} title={'Wave To Wave'} color="wave">
                <MessengerCustomerChat
                    pageId={this.state.pageId}
                    appId={this.state.appId}
                    minimized={true}
                    shouldShowDialog={false}
                />
                <div className="main-content wavetowave">
                    <div className="container">
                        <p className="font-weight-bold text-center mb-2 mt-2" style={{ fontSize: "16px" }}><span className="text-danger">{this.state.rate}</span>  ချပ်စ်ပြား <br></br> ကို ID <span className="text-primary">{this.state.userId}</span> သို့ ရောက်ရှိပါမည်။ </p>

                        {
                            !this.state.isComplete && <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setTabIndex(index)}>
                                <TabList id="tabList">
                                    <Tab disabled={true}><span className="item-tab">1. ငွေလွှဲခြင်း</span></Tab>
                                    <Tab disabled={true}><span className="item-tab">2. သင်၏ Wavepay နံပါတ်</span></Tab>
                                    <Tab disabled={true}><span className="item-tab">3. လုပ်ငန်းစဥ်နံပါတ် အတည်ပြုပါ။</span></Tab>
                                    {/* <Tab disabled={true}><span className="item-tab">4. Complete</span></Tab> */}
                                </TabList>

                                <TabPanel>

                                    <p className="text-center mb-3 ml-1 mr-1" style={{ fontSize: "14px" }}><span className="font-weight-bold text-danger">"{"WavePay => WavePay"} "</span> မှတဆင့် အောက်ပါ လက်ခံသူ wavepay နံပါတ်သို့ ငွေလွှဲပါ။</p>
                                    {/* {this.state.isLoading && <ReactLoading type={'balls'} color={'#54f'} height={'20px'} width={'20px'} />} */}
                                    <div className="wrraper-guide text-center">
                                        <div className="wrapper-img-guide">
                                            <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/wave/pic.png" alt="" />
                                        </div>
                                        <p className="mt-3"></p>

                                        <div className="noteInfo">
                                            <p className="text-justify">
                                                {"မှတ်ချက်။ ။ ငွေလွှဲမည့် ကစားသူသည် ငွေလွှဲရမည့် Game wave pay wallet နံပါတ်ထံသို့ မှန်ကန်စွာ ငွေလွှဲခြင်း ဖြစ်ရပါမည်။ wave နံပါတ် မှားလွဲဲခြင်း၊ စကားဝှက်ဖြင့် ငွေလွှဲလာခြင်းတို့အား Game ဘက်မှ မည်သို့မျှတာဝန်ယူ ဖြေရှင်းပေးမည်မဟုတ်ပါ။ (Wave pay နံပါတ် အပြောင်းအလဲ ရှိမည်ဖြစ်သောကြောင့် ငွေမလွှဲမီ နံပါတ်အား သေချာစွာ စစ်ဆေးပြီးမှ ငွေလွှဲရန် အကြံပြုပါသည်)"}
                                            </p>
                                        </div>

                                        <div className="ml-2 mr-2 mt-4">
                                            {this.state.accountList.map((acc, i) => {
                                                return (
                                                    <div className="wave-border d-flex justify-content-between" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div className="ml-2">
                                                            <p className="text-left " style={{ fontWeight: "700" }}>Receiver: {acc.phone}</p>
                                                        </div>
                                                        <div className="mr-2">
                                                            <button onClick={() => this.onCopy(acc.phone)} type="button" className="btn btn-primary btn-sm ml-2">Copy</button>
                                                        </div>
                                                    </div>)
                                            })}
                                        </div>

                                        <p className="text-danger mt-2">{"Wavepay မှ ငွေလွဲဲခြင်း ပြီးဆုံးပါက ဆက်ရန် ကိုနှိပ်ပါ။"}</p>


                                    </div>
                                    <div className="text-center mb-3 mt-4">
                                        <button type="button" className="btn btn-primary btn-custom" onClick={() => this.setTabIndex(1)} >ဆက်ရန်</button>
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className="wrapper-tab1 wrapper-tab">
                                        <br></br><br></br><br></br><br></br><br></br>
                                        <p className="mb-3 text-center font-size-big">ကျေးဇူးပြု၍ သင်၏ wave အကောင့်နံပါတ်အား <br></br> အောက်ဖော်ပြပါနေရာတွင် ရိုက်ထည့်ပါ။</p>
                                        <input type="text" className="form-control input-ref" placeholder="သင်၏ Wave အကောင့်နံပါတ် ထည့်ရန်" aria-label="Wave ID" aria-describedby="basic-addon1" value={this.state.waveAccount} onChange={(e) => this.onTextChangewaveAccount(e)}></input>

                                        <div className="two-button text-center mt-4 mb-4">
                                            <button type="button" className="btn btn-secondary mr-1 btn-custom" onClick={() => this.setTabIndex(0)} >နောက်သို့</button>
                                            <button type="button" className="btn btn-primary ml-1 btn-custom" onClick={() => this.onContinueStep3()} >ဆက်ရန်</button>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="wrapper-tab2 wrapper-tab">
                                        <div className="text-center mt-3 mb-3">
                                            <img className="img-fluid img-gif" style={{ width: "70%" }} src="images/wave/giftwave.gif" alt="" />
                                        </div>
                                        <p className="text-center mt-2 mb-2">"wave မှ သင်ငွေလွှဲထားသော လုပ်ငန်းစဥ်နံပါတ်အား <br></br> အောက်ဖော်ပြပါနေရာတွင် ရိုက်ထည့်ပါ။ </p>
                                        <input type="number" className="form-control input-ref" placeholder="ငွေလွှဲလုပ်ငန်းစဥ်နံပါတ် ရိုက်ထည့်ရန်" onChange={(e) => this.onTextChange(e)} value={this.state.tid} aria-label="Ref Number" aria-describedby="basic-addon1"></input>

                                        <div className="two-button text-center mt-4 mb-4">
                                            <button type="button" className="btn btn-secondary mr-1 btn-custom" onClick={() => this.setTabIndex(1)} >နောက်သို့</button>
                                            <button type="button" className="btn btn-primary ml-1 btn-custom" onClick={() => this.onSubmit()} >Confirm</button>
                                        </div>
                                    </div>

                                </TabPanel>
                                {/* <TabPanel>
                                <div className="wrapper-tab3 text-center" style={{ height: "450px" }}>
                                    <h3 className="text-danger">Congrats!</h3>
                                    <p className="font-weight-bold">You've completed the purchase.</p>
                                    <p className="font-weight-bold">Please return the game app to receive chips.</p>
                                    <p className="font-weight-bold">It can take 5 minutes.</p>
                                    <br></br><br></br><br></br>
                                    <p className="font-weight-bold text-primary"><i>NOTE: If you don't receive chips, please check the transaction in "Shop History" and make sure that your Ref.No is correct.</i></p>
                                </div>
                            </TabPanel> */}
                            </Tabs>
                        }

                        {
                            this.state.isComplete && <div className="wrapper-complete text-center mt-3">
                                <div style={{ alignSelf: 'center' }} >
                                    <h3 className="text-primary">Congrats!</h3>
                                    <p className="font-weight-bold">သင်၏ ချပ်စ်ပြားဝယ်ယူမှု ငွေလွဲဲခြင်း အောင်မြင်သွားပါပြီ!</p>
                                    <p className="font-weight-bold">၅မိနစ်အတွင်း သင်၏ ဂိမ်းအကောင့် ID ထဲသို့ ဝယ်ယူထားသော ချပ်စ်ပြားများ ရောက်ရှိလာပါလိမ့်မည်။</p>
                                    <br></br><br></br><br></br>
                                    <br></br><br></br><br></br>
                                    <p className="text-danger font-weight-bold" style={{ fontSize: "20px" }}>မှတ်ချက်။  ။</p>
                                    <p className="text-secondary">
                                        Come back to game and check the chips. <br></br>
                                        ချပ်စ်ပြားဝယ်ယူထားသော ဂိမ်း ID နံပါတ်နှင့် wave pay မှ ငွေလွှဲထားသော လုပ်ငန်းစဥ်နံပါတ်ပါသောပုံအား ဂိမ်း Facebook page ၏ အက်ဒမင်ထံသို့ ပေးပို့ ဆက်သွယ်အကြောင်းကြားပေးပါ။
                                        ဂိမ်း၏ အက်ဒမင်များမှ ကူညီဖြေရှင်းပေးပါလိမ့်မည်။
                                        <br></br>
                                        ကစားသူအားလုံးကို ကျေးဇူးအထူးတင်ရှိပါသည်။
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </MainLayout >
        );
    }
}